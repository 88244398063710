import React from 'react';
import { Modal, Button, Checkbox, Spin, message } from 'antd';
import request from 'superagent';
import { CountryCode, GLGlobal, MessageHelper, NotificationType, PrimaryLanguageLocale } from 'gl-commonui';
import "./privacypolicy.less"
import { SchoolLocale } from '@app/locales/localeid';

interface PrivacyPolicyModalState {
    isModalVisible: boolean;
    checked: boolean;
    readMore: boolean;
    loading: boolean;
    htmlContent: any;
    isLanguageEnglish: boolean;
}

interface PrivacyPolicyModalProps {
    isPrivacyPolicyAccepted?: boolean;
    countryCode: string;
    handleIsPrivacyPolicyAccepted: (params) => void;
    handleUserIpAddress: (params) => void;
}

class PrivacyPolicyModal extends React.Component<PrivacyPolicyModalProps, PrivacyPolicyModalState> {
    language
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            checked: false,
            readMore: false,
            loading: false,
            htmlContent: '',
            isLanguageEnglish: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        if (this.props.countryCode === CountryCode.Russsia) {
            this.fetchHtmlContent(this.props.countryCode, PrimaryLanguageLocale.Russian);
        }

        this.language = PrimaryLanguageLocale.Russian;
        this.setState({ isModalVisible: true });
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<PrivacyPolicyModalState>): void {
        const title = document.querySelector('h1') as HTMLElement;
        if (title) {
            title.style.textAlign = 'center';
            title.style.marginTop = '8px';
            title.style.borderBottom = '1px solid #ccc';
        }

        if (prevState.readMore !== this.state.readMore || prevState.isLanguageEnglish !== this.state.isLanguageEnglish) {
            const siteInfo = document.getElementsByClassName("site-info")[0] as HTMLElement;
            if (siteInfo) {
                siteInfo.style.maxHeight = "320px";
                siteInfo.style.overflowY = "auto";
            }
        }
    }

    handleOk = async () => {
        await request.get(`https://ipapi.co/json?key=${process.env.ipApiKey}`).then((result) => {
            this.props.handleUserIpAddress(result.body.ip)
        });

        this.props.handleIsPrivacyPolicyAccepted(true);
        this.setState({ isModalVisible: false });
    };

    handleCancel = () => {
        this.props.handleIsPrivacyPolicyAccepted(false);
        MessageHelper.Message(NotificationType.Failed, GLGlobal.intl.formatMessage({ id: SchoolLocale.PrivacyPolicyRejectionMessage }));

        this.setState({ isModalVisible: false });
    };

    onCheckboxChange = (e) => {
        this.setState({ checked: e.target.checked });
    };

    toggleReadMore = () => {
        this.setState((prevState) => ({ readMore: !prevState.readMore }));
    };

    changeLanguage = async () => {
        if (this.state.isLanguageEnglish === false) {
            this.language = PrimaryLanguageLocale.English;
            await this.fetchHtmlContent(this.props.countryCode, this.language);
        }
        else {
            this.language = PrimaryLanguageLocale.Russian;
            await this.fetchHtmlContent(this.props.countryCode, this.language);
        }
        this.setState((prevState) => ({ isLanguageEnglish: !prevState.isLanguageEnglish }));
    }

    async fetchHtmlContent(region: string, language: string) {
        await request.get(`${GLGlobal.authorityUrl()}/Copyright/PrivacyPolicyV2?region=${region}&language=${language}`).then(res => (res.text))
            .then(data => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(data, 'text/html');
                const bodyContent = doc.querySelector('.site-body').innerHTML;
                this.setState({ loading: false, htmlContent: bodyContent });
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }

    render() {
        const { readMore, loading, htmlContent, isLanguageEnglish } = this.state;
        const shortContent = htmlContent.slice(0, 700);

        return (
            <div>
                <Modal
                    visible={this.state.isModalVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={800}
                    maskClosable={false}
                    closable={false}
                    footer={this.state.readMore ? [
                        <Button key="accept" type="primary" onClick={this.handleOk} disabled={!this.state.checked}>
                            {GLGlobal.intl.formatMessage({ id: SchoolLocale.PrivacyPolicyAccept })}
                        </Button>,
                        <Button key="reject" type="danger" onClick={this.handleCancel}>
                            {GLGlobal.intl.formatMessage({ id: SchoolLocale.PrivacyPolicyReject })}
                        </Button>,
                    ] : null}
                >
                    <Button type="default" onClick={this.changeLanguage}>
                        {isLanguageEnglish ? "TRANSLATE TO RUSSIAN" : "TRANSLATE TO ENGLISH"}
                    </Button>
                    {loading ? <Spin /> :
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: readMore ? htmlContent : shortContent }} />
                            <p className='policy-agreement'> {GLGlobal.intl.formatMessage({ id: SchoolLocale.PrivacyPolicyAgreement })} </p>
                            {htmlContent.length > 200 && (<a className='read-more' onClick={this.toggleReadMore}> {readMore ? '' : GLGlobal.intl.formatMessage({ id: SchoolLocale.PrivacyPolicyReadMore })}</a>)}
                            {this.state.readMore ?
                                <Checkbox className='privacy-policy-checkbox' onChange={this.onCheckboxChange}>
                                    {GLGlobal.intl.formatMessage({ id: SchoolLocale.PrivacyPolicyCheckboxText })}
                                </Checkbox> : null}
                        </div>
                    }
                </Modal>
            </div>
        );
    }
}

export default PrivacyPolicyModal;
